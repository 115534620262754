<template>
    <div>
        <b-button size="sm" @click="goBack()" class="mb-2" variant="info">
            <font-awesome-icon icon="fa-arrow-left"/> Back
        </b-button>
        <div class="card">
            <div class="card-body">
                <b-row v-if="user != {}">
                    <b-col cols="3">
                        <img style="width: 100%;" :src="require('@/assets/images/small/img-4.jpg')" alt="Profile Image">
                    </b-col>
                    <b-col cols="9">
                        <b-row>
                            <b-col class="mb-4" cols="2">
                                <h5>Name:</h5> 
                            </b-col>
                            <b-col cols="10">
                                <b-form-input v-model="user['name']" style="width: 50%;"></b-form-input>
                            </b-col>

                            <b-col class="mb-4" cols="2">
                                <h5>Job Title: </h5> 
                            </b-col>
                            <b-col cols="10">
                                <b-form-input v-model="user['job_title']" style="width: 50%;"></b-form-input>
                            </b-col>
                            
                            <b-col class="mb-4" cols="2">
                                <h5>Reporting Line:</h5> 
                            </b-col>
                            <b-col cols="10">
                                <b-form-input v-model="user['reporting_line']" style="width: 50%;"></b-form-input>
                            </b-col>
                            
                            <b-col class="mb-4" cols="2">
                                <h5>Management Grade</h5> 
                            </b-col>
                            <b-col cols="10">
                                <b-form-input v-model="user['management_grade']" style="width: 50%;"></b-form-input>
                            </b-col>
                            
                            <b-col class="mb-4" cols="2">
                                <h5>Mobile:</h5> 
                            </b-col>
                            <b-col cols="10">
                                <b-form-input v-model="user['phone_number']" style="width: 50%;"></b-form-input>
                            </b-col>
                            
                            <b-col class="mb-4" cols="2">
                                <h5>Email:</h5> 
                            </b-col>
                            <b-col cols="10">
                                <b-form-input v-model="user['email']" style="width: 50%;"></b-form-input>
                            </b-col>
                            
                        </b-row>
                    </b-col>
                    <b-col cols="12">
                        <h5>Employment Date:</h5> 
                    </b-col>
                    <b-col class="mb-4" cols="12">
                        <b-form-input v-model="user['employment_date']"></b-form-input> 
                    </b-col>

                    <b-col cols="12">
                        <h5>Education</h5> 
                        <p>Description of education history</p>
                    </b-col>
                    <b-col class="mb-4" cols="12">
                        <b-form-input v-model="user['education']"></b-form-input> 
                    </b-col>

                    <b-col cols="12">
                        <h5>Roles</h5> 
                        <p>Description of assigned roles</p>
                    </b-col>
                    <b-col class="mb-4" cols="12">
                        <b-form-input v-model="user['roles']"></b-form-input> 
                    </b-col>

                    <b-col cols="12">
                        <h5>Responsibilities</h5>
                        <p>Description of assigned Responsibilities</p>
                    </b-col>
                    <b-col class="mb-4" cols="12">
                        <b-form-input v-model="user['responsibilities']"></b-form-input> 
                    </b-col>

                    <b-col cols="12">
                        <h5>Table of KPI's</h5>
                        add webix table
                    </b-col>
                    <b-col class="mb-4" cols="12">
                        <b-form-input></b-form-input> 
                    </b-col>

                    <b-col cols="12">
                        <h5>Skills</h5> 
                        <p>List of user's skills</p>
                    </b-col>
                    <b-col class="mb-4" cols="12">
                        <b-form-input v-model="user['skills']"></b-form-input> 
                    </b-col>

                    <b-col class="mb-4" cols="12">
                        <h5>Career Path / Succession Plan</h5>
                        <p>My path to success</p>
                    </b-col>
                    <b-col class="mb-4" cols="12">
                        <b-form-input v-model="user['career_path']"></b-form-input> 
                    </b-col>

                    <b-col class="mb-4" cols="12">
                        <h5>Table of Leave</h5> 
                        <div v-if="user.leave != undefined">
                            <p><strong>Leave Days:</strong> {{user.leave.leave_days}}</p>
                            <p><strong>Leave Remaining:</strong> {{user.leave.leave_remaining}}</p>
                            <p><strong>Leave Cycle:</strong> {{user.leave.leave_cycle}}</p>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-else>
                    <b-col cols="12">
                        <b-alert>Loading</b-alert>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
import { levelComputed } from "@/state/helpers";
import router from "@/router";
import _ from 'lodash'
export default {
    data:() => ({
        user: {},
    }),
    methods:{
        returnHome(){
            //*Go back to all users if the level changes while in edit mode
            router.push('/people');
        },

        loadUserData(){
            this.user = {};
            var user_index = _.findIndex(this.level_users, {id: Number(this.$route.params.user_id)})
            if(user_index != -1){
                this.user = this.level_users[user_index];
            }
            else{
                this.returnHome();
            }
        },
        goBack(){
            router.push('/people');
        }
    },
    watch:{
        '$route.params.user_id'(){
            this.loadUserData();
        }
    },
    computed:{
        ...levelComputed,
    },
    mounted(){
        this.loadUserData();
        this.$eventHub.$on('newLevelSelected', this.returnHome);
    },
    beforeDestroy(){
        this.$eventHub.$off('newLevelSelected');
    }
}
</script>

<style>
</style>
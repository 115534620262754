<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <EditUser :edit="editUser" v-if="isNewUser"/>
        <User v-else/>
    </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import User from "@/components/people/user.vue"
import EditUser from "@/components/people/edit-user.vue"
export default {
    page:{
        title: 'People'
    },
    components: {
        Layout,
        PageHeader,
        User,
        EditUser
    },
    computed:{
        isNewUser(){
            if(this.$route.params.user_id == -1 || this.editUser == true){
                return true;
            }
            else{
                return false;
            }
        },
        user(){
            return this.users_response.user_data[0];
        },
        feature_set(){
            return this.users_response.feature_set;
        }
    },
    data:()=>({
        
        title: "Admin",
        items: [
            {
                text: "Home",
                href: "/",
            },
            {
                text: "Admin",
                active: true,
            },
        ],
        editUser: false,
    }),
    methods:{
        
    },
    mounted(){

    }
}
</script>

<style>

</style>
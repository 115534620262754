<template>
    <div class="card">
        <div v-if="current_step == 1" class="card-body">
            <h4 class="card-title mb-5">Step 1</h4>
            <form class="form-horizontal" role="form">
                <b-row>
                    <!-- left column -->
                    <b-col cols="5">
                        <b-form-group class="mb-3" id="name" label-cols-lg="4" label="Name" label-for="text">
                            <b-form-input for="text" v-model="user.full_name"></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col cols="5">
                        <b-form-group class="mb-3" id="email" label-cols-lg="4" label="Email" label-for="text">
                            <b-form-input type="text" for="text" v-model="user.email"></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col cols="5">
                        <b-form-group class="mb-3" id="id_number" label-cols-lg="4" label="ID Number" label-for="idnum">
                            <b-form-input type="text" for="idnum" v-model="user.id_number"></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col cols="5">
                        <b-form-group class="mb-3" id="job_title" label-cols-lg="4" label="Job Title" label-for="text">
                            <b-form-input for="text" v-model="user.job_title"></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col cols="5">
                        <b-form-group class="mb-3" id="reporting_line" label-cols-lg="4" label="Reporting Line" label-for="text">
                            <b-form-input for="text" v-model="user.reporting_line"></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col cols="5">
                        <b-form-group class="mb-3" id="management_grade" label-cols-lg="4" label="Management Grade" label-for="text">
                            <b-form-input for="text" v-model="user.management_grade"></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col cols="5">
                        <b-form-group class="mb-3" id="employment_date" label-cols-lg="4" label="Employment Date" label-for="text">
                            <b-form-input type="date" for="text" v-model="user.employment_date"></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col cols="5">
                        <b-form-group class="mb-3" id="days_leave" label-cols-lg="4" label="Days Leave" label-for="text">
                            <b-form-input type="number" for="text" ></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col cols="5">
                        <b-form-group class="mb-3" id="password" label-cols-lg="4" label="Password" label-for="text">
                            <b-form-input autocomplete="nope" type="text" for="text" v-model="user.password"></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col cols="5">
                        <b-form-group class="mb-3" id="confirm_password" label-cols-lg="4" label="Confirm Password" label-for="text">
                            <b-form-input  type="text" for="rtext" v-model="user.confirm_password"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <!-- right column -->
                    <b-col cols="4">
                        

                        

                        

                        

                        
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="10">
                        <b-form-group class="mb-3 mt-2" label="Education" label-for="education">
                            <b-form-textarea no-resize v-model="user.education" id="education"></b-form-textarea>
                        </b-form-group>
                    </b-col>

                    <b-col cols="10">
                        <b-form-group class="mb-3 mt-2" label="Roles" label-for="roles">
                            <b-form-textarea no-resize v-model="user.roles" id="roles" ></b-form-textarea>
                        </b-form-group>
                    </b-col>

                    <b-col cols="10">
                        <b-form-group class="mb-3 mt-2" label="Responsibilities" label-for="responsibilities">
                            <b-form-textarea no-resize v-model="user.responsibilities" id="responsibilities"></b-form-textarea>
                        </b-form-group>
                    </b-col>

                    <b-col cols="10">
                        <b-form-group class="mb-3 mt-2" label="Career Path / Succession Plan" label-for="path">
                            <b-form-textarea no-resize v-model="user.career_path" id="path"></b-form-textarea>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="10">
                        <div class="mb-3 mt-2">
                            <b-button variant="success" @click="createNewUser()">
                                <i  class="bx bx-check font-size-16 align-middle me-2" ></i> Next Step
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </form>
        </div>

        <div v-if="current_step == 2" class="card-body">
            <h4 class="card-title mb-5">Step 2 - Set User Permissions</h4>
            <b-row>
                <b-col cols="12">
                    <b-form-group class="mb-3" label="Permissions" label-for="permissions">
                        <webix-ui :config="permissionOptions" v-model="empty_object"></webix-ui>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button @click="finishCreatingUser()" variant="success">
                        Finish
                    </b-button>
                </b-col>
                
            </b-row>
        </div>
    </div>
</template>

<script>

import { levelComputed, levelMethods, adminComputed } from "@/state/helpers";
import axios from 'axios'
import router from '@/router'
import _ from 'lodash'
export default {
    props:{
        editUser:{},
    },
    data: () => ({
        isLoading: false,
        new_user_data:null,
        empty_object:{},
        current_step: 1,
        base: process.env.VUE_APP_API_BASEURL,
        user: {
                full_name: '',
                email: '',
                id_number: '',
                job_title: '',
                reporting_line: '',
                management_grade: '',
                employment_date: '',
                roles: '',
                education: '',
                responsibilities: '',
                skills: '',
                career_path: '',
                password: '',
                level_id: '',
                confirm_password: '',
                //user_group_id: null,
        },
        permissionsToUpdate:[],
        schedule:{
            frequency:"Frequency",
        },


        permissionOptions:{
            view:"datatable", autoheight:true,id:"grid", scroll:true, css: "datatablePermissions",
            on:{
                onCheck: function(rowID, colID, state){//LOGIC FOR ENABLING ALL LOWER LEVELS OF PERMISSIONS IN THE SELECTED COLUMN
                    var el = this.$scope.$parent.$parent;

                    //* Hardcoded permission id's  :)
                    var edit = 1;
                    var view = 2;
                    var approve = 3;

                    el.togglePermission(colID,rowID);
                    if(rowID == approve && state == "on"){   
                        //eslint-disable-next-line
                        $$("grid").eachRow(function(row){
                            //eslint-disable-next-line
                            var rec = $$("grid").getItem(row)
                            if(rec.id <rowID && rec[colID] != "x"){
                                if(rec[colID]=="off"){
                                    el.togglePermission(colID, row);
                                }
                                rec[colID]="on";
                                this.updateItem(row, rec);
                            }
                        })
                    }
                    else if(rowID == view  &&  state == "off"){
                        //eslint-disable-next-line
                        $$("grid").eachRow(function(row){
                            //eslint-disable-next-line
                            var rec = $$("grid").getItem(row)
                            if(rec.id != rowID  &&  rec[colID] != "x"){
                                if(rec[colID]=="on"){
                                    el.togglePermission(colID, row);
                                }
                                rec[colID]="off";
                                this.updateItem(row,rec)
                            }
                        })
                    }
                    else if(rowID == edit  &&  state == "on"){
                        //eslint-disable-next-line
                        $$("grid").eachRow(function(row){
                            //eslint-disable-next-line
                            var rec = $$("grid").getItem(row)
                            if( rec.id == view  &&  rec[colID] != "x"){
                                if(rec[colID] == "off"){
                                    el.togglePermission(colID, row);
                                }
                                rec[colID] = "on";
                                this.updateItem(row, rec);
                            }
                        })
                    }
                }
            },
            columns:[],
        },
    }),
    methods:{
        ...levelMethods,
        createNewUser(){
            var params = this.user;
            delete params.confirm_password;
            axios.post( this.base + 'api/admin/createuser', params)
            .then((response)=>{
                this.loadLevelUsers(this.selected_level.id)
                .then(()=>{
                    let index = _.findIndex(this.level_users, {id: response.data.user_id})
                    if(index != -1){
                        this.new_user_data = _.cloneDeep(this.level_users[index]);
                        this.current_step = 2;
                        this.$nextTick(()=>{
                            this.createPermissionsTable();
                        })
                        //create perms and schedule table
                    }
                })
            })
        },
        togglePermission(feature, permission){
            this.permissionsToUpdate.push({feature_id: feature, permission_id: permission});
        },

        finishCreatingUser(){
            this.updateUserPermissions()
            .then(()=>{
                router.push('/people');
            })
        },
        updateUserPermissions(){
            return new Promise((resolve, reject)=>{
                if(this.permissionsToUpdate.length > 0){
                    var params = {
                        user_id: this.new_user_data.id,
                        permissions: this.permissionsToUpdate,
                    }
                    axios.post(this.base + 'api/admin/savepermissions', params)
                    .then(()=>{
                        resolve();
                    })
                    .catch(()=>{
                        reject();
                    })
                }
                else{
                    resolve();
                }
            })
        },

        setCurrentLevel(){
            this.user.level_id = this.selected_level.id;
        },

        //*webix table creation
        createEmailScheduleTable:function(){
            //eslint-disable-next-line
            var grid = $$("scheduleGrid");
            grid.config.columns=[
                {id:"frequency", header:"",fillspace:true},
            ];
            var i = grid.config.columns.length;

            /*for(var feature of Levels.state.team_users.user_data[0].email_scheduling){
                this.schedule[feature.feature_id]=feature.frequency;
                grid.config.columns.splice(i,0, {id:feature.feature_id, header:feature.feature_name+' email', editor:"richselect", value:feature.frequency, options:this.scheduleOptions});
            }*/
            for(var feature of this.new_user_data.email_scheduling){
                this.schedule[feature.feature_id]=feature.frequency.toLowerCase();
                
                grid.config.columns.splice(i,0, {id:feature.feature_id, header:feature.feature_name+' email', editor:"richselect", value:feature.frequency, options:this.scheduleTypes,  width: 150,css:'highlight', $cellCss:{value:"highlight"}});
            }
            grid.refresh();
            grid.refreshColumns();
            this.$nextTick(()=>{ this.isLoading = false; })
        },

        createPermissionsTable: function(){//RENDER THE PERMISSIONS TABLE WITH ALL THE FEATURES OF THE CURRENT USER,
            function custom_checkbox(obj, common, value, a, b){
                if(value=="x")//INDICATES THAT THE FEATURE ISNT IN THE FEATURE_SET OF THE USER AND SETS THAT CELL TO BLANK
                return "";
                else if(value=="on")
                return common.checkbox(obj, common , "on", a, b);
                else if(value=="off")
                return common.checkbox(obj, common , "off", a, b);
                else if(!value)
                return "<i class='fa fa-times'></i>"
            }
            //eslint-disable-next-line no-unused-vars
            return new Promise((resolve, reject)=>{
                var features = this.core.features;
                //eslint-disable-next-line
                var grid = $$("grid");
                grid.config.columns=[];
                grid.clearAll();

                //AVAILABLE PERMS
                var i = 0;//USED TO INSERT COLUMNS IN THE CORRECT ORDER
                var usedPerms=[];
                var r={};
                features.forEach(feature=>{
                    grid.config.columns.splice(i,0,{id: feature.id, header: feature.name.toUpperCase(), checkValue:"on", uncheckValue:"off", template:custom_checkbox, fillspace:1});
                    for(var perm of feature.permissions){
                        if(!usedPerms.includes(perm.id)){
                            usedPerms.push(perm.id);
                            r[perm.id]={permission: perm.name, id:perm.id};
                        }
                    }
                    i++;
                })
                grid.config.columns.splice(0,0, {id: "permission", header:""});
                grid.refreshColumns();
                usedPerms.forEach(perm=>{//Fill all rows (prevent 'undefined')
                    features.forEach(feature=>{
                        r[perm][feature.id]="x"
                    })
                })
                usedPerms.forEach(perm=>{
                    features.forEach(f=>{
                        for(var p of f.permissions){
                            if(p.id==perm){
                                r[perm][f.id]="off";
                            }
                        }
                    })
                })
                //if(!Levels.state.isNewUser){//SETS THE ENABLED FEATURES TO ON FOR THE EXISTING USER
                    this.new_user_data.user_permissions.forEach(perm=>{
                        var f_id = perm.pivot.feature_id;
                        //var index = _.findIndex(features, function(o){return o.id == f_id})
                        r[perm.pivot.permission_id][f_id]="on";
                    })
                //}
                usedPerms.forEach(element=>{//ADD EACH ROW TO THE TABLE
                    grid.parse(r[element]);
                });
                this.$nextTick(()=>{
                    grid.refreshColumns();
                    resolve();
                })
                
            });
        },
        
    },
    computed:{
        ...levelComputed,
        ...adminComputed,
    },
    mounted(){
        this.$eventHub.$on('newLevelSelected', this.setCurrentLevel);

        this.setCurrentLevel();
    },
    beforeDestroy(){
        this.$eventHub.$off('newLevelSelected');
    }
    
}
</script>

<style>
.webix_table_checkbox,.webix_table_radio{
    -ms-transform: scale(1.3); /* IE */
    -moz-transform: scale(1.3); /* FF */
    -webkit-transform: scale(1.3); /* Safari and Chrome */
    -o-transform: scale(1.3); /* Opera */
    transform: scale(1.3);
}
</style>